import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import SubMenu from '../SubMenu';
import Header from '../Header';
import TopMenu from '../TopMenu';
import Footer from '../Footer';

import { menuNodesSorter } from '../../utils';

import styles from './styles.module.scss';

const headerImageMap = {
  overview: 'https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fbanners%2Foverview.jpg?alt=media&token=9f498528-343e-46c3-8670-13a54fdbfb00',
  databases: 'https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fbanners%2Fdatabase.jpg?alt=media&token=fe2a3466-76ed-446e-b4a1-d50d567bcfdb',
  geospatial: 'https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fbanners%2Fgeospatial.jpg?alt=media&token=3e8de946-8e68-49c0-83de-d74216332962',
  countries: 'https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fbanners%2Fcountry-basins.jpg?alt=media&token=21ab574d-e2ed-4a4e-8230-b662c1766214',
  'data-analysis': 'https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fbanners%2Fdata-analysis.jpg?alt=media&token=69f82423-c78c-454e-8336-cf6d79a439cb',
  activities: 'https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fbanners%2Factivities.jpg?alt=media&token=5afd4212-620a-49c3-948d-2b534839cd28',
  publications: 'https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fbanners%2Fpublications.jpg?alt=media&token=46de747e-af36-48ef-9dfd-32be6ae53754',
};

const indexPageWrapper = pageName => {
  class IndexPage extends Component {
    constructor() {
      super();

      this.state = {
        subPages: [],
      };
    }

    componentDidMount = () => {
      const { data: { allMarkdownRemark: { edges } } } = this.props;

      const subPages = edges
        .sort(menuNodesSorter)
        .map(({ node }) => {
          const { path, title } = node.frontmatter;

          // Exclude the section "index" file, to be rendered
          // in the body of the page, and any potential child pages.
          if (path.endsWith('/index') || path.split('/').length >= 4) {
            return null;
          }

          return {
            link: path.endsWith('/') ? path : `${path}/`,
            label: title,
            children: this.findPageChildren(path),
          };
        })
        .filter(Boolean);

      this.setState({
        subPages,
      });
    }

    getHeaderImage = () => {
      const { location } = this.props;
      const pageKey = Object.keys(headerImageMap).find(key => (
        location.pathname.startsWith(`/${key}`) || location.pathname.startsWith(`/aquastat/${key}`)
      ));

      return headerImageMap[pageKey];
    }

    findPageChildren(parentPath) {
      const { data: { allMarkdownRemark: { edges } } } = this.props;

      return edges.filter(({ node }) => {
        const nodeProps = node.frontmatter;

        // If the node path is shorter or equal to the parent
        // path it means it's not a children. Also, if it's a
        // 4th-level page, don't include it in the direct children list.
        const isChildPage = nodeProps.path.length <= parentPath.length;
        const isFourthLevel = nodeProps.path.split('/').length > 4;

        if (isChildPage || isFourthLevel) {
          return false;
        }

        if (nodeProps.path.startsWith(parentPath)) {
          return true;
        }

        return false;
      }).sort(menuNodesSorter);
    }

    renderSubMenu = () => {
      const { location } = this.props;
      const { subPages } = this.state;

      return <SubMenu links={subPages} currentPath={location.pathname} />;
    }

    renderBody = () => {
      const { data: { allMarkdownRemark: { edges } } } = this.props;
      const indexEdge = edges.find(e => e.node.frontmatter.path === `/${pageName}/index`);

      if (!indexEdge) {
        return null;
      }

      return (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: indexEdge.node.html }}
        />
      );
    }

    render() {
      const { data: { site }, location } = this.props;
      const headerImage = this.getHeaderImage();

      return (
        <div>
          <Helmet
            title={site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'FAO AQUASTAT' },
              { name: 'keywords', content: `fao, water, aquastat, water production, ${pageName}` },
            ]}
          />
          <Header siteTitle={site.siteMetadata.title} location={location} />

          <div className="app-wrapper">
            <h2 className="site-title">AQUASTAT - FAO&apos;s Information System on Water and Agriculture</h2>
            <TopMenu location={location.pathname} />

            {headerImage && (
              <div className={styles.headerImage}>
                <img src={this.getHeaderImage()} alt="FAO AQUASTAT" />
              </div>
            )}

            <div className={styles.wrapper}>
              <div className={styles.subMenuWrapper}>
                {this.renderSubMenu()}
              </div>

              {this.renderBody()}
            </div>
          </div>

          <Footer />
        </div>
      );
    }
  }

  IndexPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  return IndexPage;
};

export default indexPageWrapper;
