import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import styles from './styles.module.scss';

class SubMenu extends Component {
  renderChildren = ({ node }) => {
    const { currentPath } = this.props;
    const { path, title } = node.frontmatter;
    const childPath = path.endsWith('/') ? path : `${path}/`;
    const activePath = currentPath.endsWith('/') ? currentPath : `${currentPath}/`;
    const isActiveChildren = !!activePath && activePath.includes(childPath);

    return (
      <Link
        key={childPath}
        to={childPath}
        className={classnames(styles.childrenLink, { [styles.activeChildrenLink]: isActiveChildren })}
        activeClassName={styles.activeChildrenLink}
      >
        {title}
      </Link>
    );
  }

  renderMenu = ({ label, link, children }) => {
    const { currentPath } = this.props;


    const prefixedLink = window.location.hostname.includes('fao.org') ? `/aquastat${link}` : link;
    const isActivePath = !!currentPath && (currentPath.includes(prefixedLink) || `${currentPath}/` === prefixedLink);
    const childPaths = (children || []).map(({ node: { frontmatter: { path } } }) => {
      const prefixedPath = window.location.hostname.includes('fao.org') ? `/aquastat${path}` : path;
      return prefixedPath.endsWith('/') ? prefixedPath : `${prefixedPath}/`;
    });
    const shouldRenderChildren = !!children && !!children.length && (
      currentPath === prefixedLink
      || `${currentPath}/` === prefixedLink
      || childPaths.includes(currentPath)
      || childPaths.includes(`${currentPath}/`)
    );

    return (
      <div key={label} className={styles.linkWrapper}>
        <Link to={link} className={classnames(styles.link, { [styles.activeLink]: isActivePath })} activeClassName={styles.activeLink}>
          {label}
        </Link>
        {shouldRenderChildren && (
          <div className={styles.childrenLinkWrapper}>
            {children.map(this.renderChildren)}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { links } = this.props;

    if (!links) {
      return null;
    }

    return (
      <div className={styles.wrapper}>
        {links.map(this.renderMenu)}
      </div>
    );
  }
}

SubMenu.propTypes = {
  links: PropTypes.array.isRequired,
  currentPath: PropTypes.string,
};

SubMenu.defaultProps = {
  currentPath: '',
};

export default SubMenu;
